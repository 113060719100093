<template>
  <div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2 text-lg-right">
        {{ this.$t("assignments.fields.name") }}
        <span class="text-theme">*</span>
      </label>
      <div class="col-md-8">
        <b-input
          type="text"
          disabled
          class="form-control"
          :state="true"
          :value="generalData.title"
        />
      </div>
    </div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2 text-lg-right">
        {{ this.$t("assignments.fields.description") }}
      </label>
      <div class="col-md-8">
        <b-input
          disabled
          :value="generalData.description"
        />
      </div>
    </div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2 text-lg-right">
        {{ this.$t("assignments.fields.start") }}
        <span class="text-theme">*</span>
      </label>
      <div class="col-md-4">
        <RMDateInput
          :model="'startDate'"
          :valid="true"
          :value="generalData.startDate"
          :disabled="true"
        />
        <RMTimeInput
          :model="'startTime'"
          v-bind:valid="true"
          v-bind:value="generalData.startTime"
          :disabled="true"
        />
      </div>
    </div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2 text-lg-right">
        {{ this.$t("assignments.fields.end") }}
        <span class="text-theme">*</span>
      </label>
      <div class="col-md-4">
        <RMDateInput
          :model="'endDate'"
          :valid="true"
          :value="generalData.endDate"
          :disabled="true"
        />
        <RMTimeInput
          :model="'endTime'"
          v-bind:valid="true"
          v-bind:value="generalData.endTime"
          :disabled="true"
        />
      </div>
    </div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2 text-lg-right">
        {{ this.$t("assignments.fields.studentMessage") }}
      </label>
      <div class="col-md-8">
        <b-input
          disabled
          :value="generalData.studentMessage"
        />
      </div>
    </div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2"/>
      <div class="col-form-label col-md-8">
        <div class="alert alert-secondary text-center" v-html="gamePrice"/>
      </div>
    </div>
  </div>
</template>

<script>
import RMTimeInput from "@/components/common/RMTimeInput.vue";
import RMDateInput from "@/components/common/RMDateInput.vue";
import {getCarrotsStateString} from "@/_helpers/games_helper";

export default {
  name: "NewAssignmentSummary",
  components: {RMDateInput, RMTimeInput},
  props: {
    data: {
      type: Array,
      required: true
    },
    entity: {
      type: String,
      required: true
    },
    price: {
      type: Number,
      required: true,
    },
    credit: {
      type: Number,
      required: true,
    },
    priceData: {
      type: Object,
      required: true
    }
  },
  computed: {
    generalData() {
      return this.data.find(x => x.id === "1")?.data || {};
    },
    gamePrice() {
      let price = this.priceData.current_package.studentAssignmentPrice;
      if (this.entity === "studentGroups"){
        price = this.priceData.current_package.groupAssignmentPrice;
      }
      return getCarrotsStateString(this.credit, price, price, this, true);
    },
    dateTimeFrom() {
      return `${this.generalData.startDate} ${this.generalData.startTime}`
    },
    dateTimeTo() {
      return `${this.generalData.endDate} ${this.generalData.endTime}`
    },
  },
}
</script>