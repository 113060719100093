<template>
  <b-form-timepicker
    v-model="timeValue"
    v-bind="labelsLocale"
    :locale="dtLocale"
    :state="valid"
    :disabled="disabled"
    class="m-b-5"
  />
</template>

<script>
import {getDateTimeLocale, getLocale} from "@/_helpers/locale_helper";

export default {
  name: "RMTimeInput",
  props: {
    model: {
      type: String,
      required: true,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      timeValue: this.value,
      labels: {
        de: {
          labelHours: 'Stunden',
          labelMinutes: 'Minuten',
          labelSeconds: 'Sekunden',
          labelIncrement: 'Erhöhen',
          labelDecrement: 'Verringern',
          labelSelected: 'Ausgewählte Zeit',
          labelNoTimeSelected: 'Keine Zeit ausgewählt',
          labelCloseButton: 'Schließen'
        },
      si: {
          labelHours: 'Ure',
          labelMinutes: 'Minute',
          labelSeconds: 'Sekunde',
          labelIncrement: 'Povečaj',
          labelDecrement: 'Zmanjšaj',
          labelSelected: 'Izbrani čas',
          labelNoTimeSelected: 'Čas ni izbran',
          labelCloseButton: 'Zapri'
        },
        en: {}
      }
    }
  },
  computed: {
    dtLocale() {
      return getDateTimeLocale();
    },
    labelsLocale() {
      return this.labels[getLocale()];
    }
  },
  watch: {
    timeValue: function (newValue) {
      this.$emit("valueChanged", {model: this.model, value: newValue})
    },
    value: function (newValue) {
      this.timeValue = newValue;
    }
  }
}
</script>