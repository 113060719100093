<template>
  <div>
    <div class="row m-b-10">
      <div class="col">
        <b-alert show :variant="alertVariant" class="text-center" style="font-size: medium">
          {{ $t("assignments.numberOfTasks") }}: {{this.currentNumTasks}} / {{this.maxTasks}}
        </b-alert>
      </div>
    </div>
    <div class="row">
      <div
        class="col-xs-12 col-sm-12 col-md-6 col-xl-4 col-xxl-3 col-xxxl-3"
        v-for="game in selectedGames"
        v-bind:key="game"
      >
        <RMPanel :title="getTitle(game)">
          <template v-slot:panelBody>
            <new-game-info
              :game="game"
              :is-loading="false"
              :game-info="gamesInfo && gamesInfo[game]"
              :default-config="data[game] || {}"
              :credit="0"
              :assignment-mode="true"
              @onConfigChange="onConfigChange"
            ></new-game-info>
          </template>
        </RMPanel>
      </div>
    </div>
  </div>
</template>

<script>
import RMPanel from "@/components/common/RMPanel.vue";
import NewGameInfo from "@/components/games/dispatch/NewGameInfo.vue";

export default {
  name: "NewAssignmentDetails",
  components: {NewGameInfo, RMPanel},
  data() {
    return {
      maxTasks: 15,
      currentNumTasks: 0,
      config: this.data || {}
    }
  },
  props: {
    gamesInfo: {
      type: Object,
      required: true,
    },
    selectedGames: {
      type: Array,
      required: true,
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    alertVariant() {
      if (this.currentNumTasks > this.maxTasks) {
        return "danger";
      }
      return "success";
    }
  },
  methods: {
    getTitle(gameId) {
      return this.$t('games.games.' + gameId + '.name')
    },
    areInputDataValid() {
      return this.currentNumTasks <= this.maxTasks;
    },
    calculateNumberOfTasks() {
      let numberTasks = 0;
      const games = Object.keys(this.config);
      games.forEach((game) => {
        if(this.selectedGames.includes(game) && Object.keys(this.config[game]).includes("num_tasks")){
          numberTasks = numberTasks + parseInt(this.config[game]["num_tasks"]);
        }
      });
      this.currentNumTasks = numberTasks;
    },
    onConfigChange(data) {
      this.config[data['game']] = data['config'];

      const newConfig = {...this.config};
      const gameConfigKeys = Object.keys(newConfig);
      gameConfigKeys.forEach((gameKey) => {
        if(!this.selectedGames.includes(gameKey)){
          delete newConfig[gameKey];
        }
      })
      this.config = newConfig;

      this.calculateNumberOfTasks();
      const validData = this.areInputDataValid();
      this.$emit("dataChange", {data: this.config, valid: validData})
    },
  }
}
</script>