<template>
  <div class="row">
    <div
      class="col-xs-12 col-sm-12 col-md-6 col-xl-4 col-xxl-3 col-xxxl-3"
      v-for="game in educationalGames"
      v-bind:key="game.id"
      @click="() => onCbClick(game.id)"
    >
      <b-alert show variant="secondary">
        <div class="d-flex rm-panel-title justify-content-between valign-middle">
          {{ getTitle(game.id) }}
          <b-form-checkbox
            class="m-r-0"
            inline
            switch
            :checked="assignmentStepData.games[game.id]"
            v-on:change="() => onCbClick(game.id)"
          />
        </div>
      </b-alert>
    </div>
  </div>
</template>

<script>
import {getEducationalGames} from "@/_helpers/games_helper";

export default {
  name: "NewAssignmentGameSelection",
  data() {
    return {
      assignmentStepData: {
        games: this.mergeGames()
      }
    }
  },
  props: {
    gameInfoLoading: {
      type: Boolean,
      required: true
    },
    gamesInfo: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    educationalGames() {
      return getEducationalGames();
    },
  },
  methods: {
    mergeGames() {
      const availableGames = {}
      const allGameKeys = Object.keys(this.gamesInfo);
      const preselectedGameKeys = Object.keys(this.data || {});
      allGameKeys.forEach(gameId => {
        availableGames[gameId] = preselectedGameKeys.includes(gameId);
      });
      return availableGames;
    },
    getTitle(gameId) {
      return this.$t('games.games.' + gameId + '.name')
    },
    getSelectedGames() {
      const enabledGames = {}
      const allGameKeys = Object.keys(this.assignmentStepData.games);
      allGameKeys.forEach(gameId => {
        if (this.assignmentStepData.games[gameId] === true){
          enabledGames[gameId] = true;
        }
      })
      return enabledGames;
    },
    areInputDataValid() {
      return Object.keys(this.getSelectedGames()).length > 0;
    },
    onCbClick(gameId) {
      this.assignmentStepData.games[gameId] = !this.assignmentStepData.games[gameId];
      const validData = this.areInputDataValid();
      this.$emit("dataChange", {data: this.getSelectedGames(), valid: validData})
    },
  },
}
</script>