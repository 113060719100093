<template>
  <b-form-datepicker
    v-model="dateValue"
    :value="dateValue"
    v-bind="labelsLocale"
    :min="minDate"
    :locale="dtLocale"
    :state="valid"
    :disabled="disabled"
    class="m-b-5"
  />
</template>
<script>
import {getDateTimeLocale, getLocale} from "@/_helpers/locale_helper";

export default {
  name: "RMDateInput",
  props: {
    model: {
      type: String,
      required: true,
    },
    valid: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dateValue: this.value,
      labels: {
        de: {
          labelPrevDecade: 'Vorheriges Jahrzehnt',
          labelPrevYear: 'Vorheriges Jahr',
          labelPrevMonth: 'Vorheriger Monat',
          labelCurrentMonth: 'Aktueller Monat',
          labelNextMonth: 'Nächster Monat',
          labelNextYear: 'Nächstes Jahr',
          labelNextDecade: 'Nächstes Jahrzehnt',
          labelToday: 'Heute',
          labelSelected: 'Ausgewähltes Datum',
          labelNoDateSelected: 'Kein Datum gewählt',
          labelCalendar: 'Kalender',
          labelNav: 'Kalendernavigation',
          labelHelp: 'Mit den Pfeiltasten durch den Kalender navigieren'
        },
        si: {
          labelPrevDecade: 'Prejšnje desetletje',
          labelPrevYear: 'Prejšnje leto',
          labelPrevMonth: 'Prejšnji mesec',
          labelCurrentMonth: 'Trenutni mesec',
          labelNextMonth: 'Naslednji mesec',
          labelNextYear: 'Naslednje leto',
          labelNextDecade: 'Naslednje desetletje',
          labelToday: 'Danes',
          labelSelected: 'Izbrani datum',
          labelNoDateSelected: 'Datum ni izbran',
          labelCalendar: 'Koledar',
          labelNav: 'Navigacija po koledarju',
          labelHelp: "Za pomikanje po datumih uporabite smerne tipke"
        },
        en: {}
      }
    }
  },
  computed: {
    dtLocale() {
      return getDateTimeLocale();
    },
    labelsLocale() {
      return this.labels[getLocale()];
    }
  },
  watch: {
    dateValue: function (newValue) {
      this.$emit("valueChanged", {model: this.model, value: newValue})
    },
    value: function (newValue) {
      this.dateValue = newValue;
    }
  }
}
</script>