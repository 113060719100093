<template>
  <div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2 text-lg-right">
        {{ this.$t("assignments.fields.name") }}
        <span class="text-theme">*</span>
      </label>
      <div class="col-md-8">
        <b-input
          id="title"
          :state="isTitleValid()"
          type="text"
          v-model="assignmentStepData.title"
          class="form-control"
          maxlength="50"
          size="50"
        />
        <b-form-invalid-feedback id="title">
          {{ this.$t("assignments.fields.atLeast5Characters") }}
        </b-form-invalid-feedback>
      </div>
    </div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2 text-lg-right">
        {{ this.$t("assignments.fields.description") }}
      </label>
      <div class="col-md-8">
        <b-input
          type="text"
          v-model="assignmentStepData.description"
          class="form-control"
          maxlength="200"
          size="200"
        />
      </div>
    </div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2 text-lg-right">
        {{ this.$t("assignments.duration") }}
        <span class="text-theme">*</span>
      </label>
      <div class="col-md-8">
        <b-button
          v-for="(val, idx) in durations"
          :key="idx"
          :variant="getButtonVariant(val)"
          v-on:click="() => onValidityButtonClick(val)">
          {{ $t("assignments.durations." + val) }}
        </b-button>

      </div>
    </div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2 text-lg-right">
        {{ this.$t("assignments.fields.start") }}
        <span class="text-theme">*</span>
      </label>
      <div class="col-md-4">
        <RMDateInput
          :model="'startDate'"
          :valid="isDateFromValid()"
          :min-date="todayDate"
          :value="assignmentStepData.startDate"
          :disabled="assignmentStepData.validity !== 'manually'"
          @valueChanged="setValue"
        />
        <RMTimeInput
          :model="'startTime'"
          :valid="isTimeFromValid()"
          :value="assignmentStepData.startTime"
          :disabled="assignmentStepData.validity !== 'manually'"
          @valueChanged="setValue"
        />
      </div>
    </div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2 text-lg-right">
        {{ this.$t("assignments.fields.end") }}
        <span class="text-theme">*</span>
      </label>
      <div class="col-md-4">
        <RMDateInput
          :model="'endDate'"
          :valid="isDateToValid()"
          :min-date="this.assignmentStepData.startDate || todayDate"
          :value="assignmentStepData.endDate"
          :disabled="assignmentStepData.validity !== 'manually'"
          @valueChanged="setValue"
        />
        <RMTimeInput
          :model="'endTime'"
          :valid="isTimeToValid()"
          :value="assignmentStepData.endTime"
          :disabled="assignmentStepData.validity !== 'manually'"
          @valueChanged="setValue"
        />
      </div>
    </div>
    <div class="row m-b-10">
      <label class="col-form-label col-md-2 text-lg-right">
        {{ this.$t("assignments.fields.studentMessage") }}
      </label>
      <div class="col-md-8">
        <b-input
          type="text"
          v-model="assignmentStepData.studentMessage"
          class="form-control"
          maxlength="200"
          size="200"
        />
      </div>
    </div>
  </div>
</template>

<script>
import RMDateInput from "@/components/common/RMDateInput.vue";
import RMTimeInput from "@/components/common/RMTimeInput.vue";

export default {
  name: "NewAssignmentGeneral",
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {RMTimeInput, RMDateInput},
  data() {
    return {
      durations: ['today', 'tomorrow', 'thisWeek', 'nextWeek', 'manually'],
      assignmentStepData: {
        title: this.data?.title || "",
        description: this.data?.description || "",
        studentMessage: this.data?.studentMessage || "",
        validity: this.data?.validity || "today",
        startDate: this.data?.startDate || new Date().toISOString().split('T')[0],
        startTime: this.data?.startTime || "00:00:00",
        endDate: this.data?.endDate || new Date().toISOString().split('T')[0],
        endTime: this.data?.endTime || "23:59:59",
      }
    }
  },
  computed: {
    todayDate() {
      return new Date().toJSON().slice(0, 10);
    },
  },
  methods: {
    getButtonVariant(id) {
      if (id === this.assignmentStepData.validity) {
        return "primary m-r-5"
      }
      return "light m-r-5";
    },
    onValidityButtonClick(id) {
      this.assignmentStepData.validity = id;

      const today = new Date();

      if(id === 'today'){
        this.assignmentStepData.startDate = today.toISOString().split('T')[0];
        this.assignmentStepData.startTime = "00:00:00";

        this.assignmentStepData.endDate = today.toISOString().split('T')[0];
        this.assignmentStepData.endTime = "23:59:59";
      }
      else if(id === 'tomorrow'){
        let nextDay = new Date(today);
        nextDay.setDate(today.getDate() + 1);

        this.assignmentStepData.startDate = nextDay.toISOString().split('T')[0];
        this.assignmentStepData.startTime = "00:00:00";

        this.assignmentStepData.endDate = nextDay.toISOString().split('T')[0];
        this.assignmentStepData.endTime = "23:59:59";
      }
      else if(id === 'thisWeek'){
        this.assignmentStepData.startDate = today.toISOString().split('T')[0];
        this.assignmentStepData.startTime = "00:00:00";

        let lastday = new Date(today.setDate(today.getDate() - today.getDay()+7));
        this.assignmentStepData.endDate = lastday.toISOString().split('T')[0];
        this.assignmentStepData.endTime = "23:59:59";
      }
      else if(id === 'nextWeek'){
        const nextWeek = this.getNextWeekDates();

        this.assignmentStepData.startDate = nextWeek.firstDay.toISOString().split('T')[0];
        this.assignmentStepData.startTime = "00:00:00";

        this.assignmentStepData.endDate = nextWeek.lastDay.toISOString().split('T')[0];
        this.assignmentStepData.endTime = "23:59:59";
      }
    },
    isTitleValid() {
      return this.assignmentStepData.title !== "" && this.assignmentStepData.title.length >= 5;
    },
    isDateFromValid() {
      return this.assignmentStepData.startDate !== "";
    },
    isDateToValid() {
      if(this.isDateFromValid() && this.assignmentStepData.startDate > this.assignmentStepData.endDate){
        return false;
      }
      return this.assignmentStepData.endDate !== "";
    },
    isTimeFromValid() {
      return this.assignmentStepData.startTime !== "";
    },
    isTimeToValid() {
      return this.assignmentStepData.endTime !== "";
    },
    setValue(value) {
      this.assignmentStepData[value["model"]] = value["value"];
    },
    areInputDataValid() {
      if(!this.isTitleValid()){
        return false;
      }
      if(!this.isDateFromValid()){
        return false;
      }
      if(!this.isTimeFromValid()){
        return false;
      }
      return this.isTimeToValid();
    },
    getNextWeekDates() {
      // Get the current date
      const currentDate = new Date();

      // Get the current day of the week (0 is Sunday, 1 is Monday, ..., 6 is Saturday)
      const currentDayOfWeek = currentDate.getDay() - 1;

      // Calculate the difference between the current day and the next Sunday (7 - currentDayOfWeek)
      const daysUntilNextSunday = 7 - currentDayOfWeek;

      // Calculate the date for the next Sunday
      const nextSunday = new Date(currentDate);
      nextSunday.setDate(currentDate.getDate() + daysUntilNextSunday);

      // Calculate the date for the next Saturday (add 6 to the next Sunday)
      const nextSaturday = new Date(nextSunday);
      nextSaturday.setDate(nextSunday.getDate() + 6);

      // Return the results
      return {
        firstDay: nextSunday,
        lastDay: nextSaturday
      };
    }
  },
  watch: {
    assignmentStepData: {
      handler(newData) {
        const validData = this.areInputDataValid();
        this.$emit("dataChange", {data: newData, valid: validData})
      },
      deep: true
    }
  }
}
</script>