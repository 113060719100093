<template>
  <div>
    <RMPageHeader>
      <template v-slot:header>
        {{ $t('assignments.newAssignment') }}
        <small>
          <b-link v-on:click="navigateToEntityPage()">({{ entityData.name }})</b-link>
        </small>
      </template>
    </RMPageHeader>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <span v-for="(step, idx) in steps" class="m-auto" v-bind:key="idx">
          <div class="d-flex flex-column justify-content-center">
            <b-avatar :text="step.id" :variant="getWizardStepColor(idx)" class="m-auto"></b-avatar>
            <span class="m-t-5">{{ $t(step.name) }}</span>
          </div>
        </span>
      </div>
    </div>
    <hr/>
    <NewAssignmentGeneral
      v-if="currentStepIdx === 0"
      :data="steps[0]?.data || {}"
      @dataChange="handleDataChange"
    />
    <NewAssignmentGameSelection
      v-if="currentStepIdx === 1"
      :data="steps[1]?.data || {}"
      :games-info="gamesInfo"
      :game-info-loading="gamesInfoLoading"
      @dataChange="handleDataChange"
    />
    <NewAssignmentDetails
      v-if="currentStepIdx === 2"
      :selected-games="Object.keys(steps[1]?.data || {})"
      :data="steps[2]?.data || {}"
      :games-info="gamesInfo"
      @dataChange="handleDataChange"
    />
    <NewAssignmentSummary
      v-if="currentStepIdx === 3"
      :data="steps"
      :entity="entity"
      :credit="credit"
      :price="price"
      :priceData="priceData"
    />
    <hr/>
    <div class="row">
      <div class="col d-flex justify-content-center">
        <b-button
          v-on:click="goToPreviousStep()"
          :disabled="this.goToPreviousDisabled"
          class="m-r-20 w-25"
          variant="primary">
          {{ this.$t("assignments.previous") }}
        </b-button>
        <b-button
          v-on:click="goToNextStep()"
          :disabled="this.goToNextDisabled"
          class="w-25"
          variant="primary">
          <span v-if="this.currentStepIdx === this.steps.length - 1">{{ this.$t("assignments.save") }}</span>
          <span v-else>{{ this.$t("assignments.next") }}</span>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/config/PageRoutes";
import RMPageHeader from "@/components/common/RMPageHeader.vue";
import {gamesService} from "@/_services";
import {handleError} from "@/_helpers/api";
import NewAssignmentGeneral from "@/pages/mentorship/assignments/new/NewAssignmentGeneral.vue";
import NewAssignmentGameSelection from "@/pages/mentorship/assignments/new/NewAssignmentGameSelection.vue";
import NewAssignmentSummary from "@/pages/mentorship/assignments/new/NewAssignmentSummary.vue";
import NewAssignmentDetails from "@/pages/mentorship/assignments/new/NewAssignmentDetails.vue";
import {mapState} from "vuex";
import {assignmentService} from "@/_services/assignment.service";
import {mentorshipService} from "@/_services/mentorship.service";
import {
  getMyStudentAssignmentRoute, getMyStudentRoute,
  getStudentGroupAssignmentRoute,
  getStudentGroupRoute
} from "@/_helpers/routes_helper";
import {isUserLoggedIn} from "@/_helpers";
import {getEntityFromPath, getEntityIdFromPath, navigateToEntityPage} from "@/_helpers/vue.helper";

export default {
  name: "NewAssignment",
  components: {
    NewAssignmentDetails,
    NewAssignmentSummary,
    NewAssignmentGameSelection,
    NewAssignmentGeneral,
    RMPageHeader
  },
  data() {
    return {
      error: "",
      entityData: {},
      entityLoading: false,
      gamesInfo: {},
      gamesInfoLoading: false,
      priceData: {},
      priceLoading: false,
      currentStepIdx: 0,
      steps: [
        { id: "1", name: "assignments.stepGeneral", valid: false },
        { id: "2", name: "assignments.stepGames", valid: false },
        { id: "3", name: "assignments.stepDetails", valid: false },
        { id: "4", name: "assignments.stepSummary", valid: true }
      ],
    }
  },
  computed: {
    ...mapState({
      account: state => state.account,
    }),
    entity() {
      return getEntityFromPath(this);
    },
    entityId() {
      return getEntityIdFromPath(this);
    },
    goToPreviousDisabled() {
      return this.currentStepIdx <= 0;
    },
    goToNextDisabled() {
      if (this.currentStepIdx === this.steps.length - 1) {
        return this.credit < this.price;
      }
      return !this.steps[this.currentStepIdx]?.valid;
    },
    price() {
      if(this.entity === "studentGroups"){
        return this.priceData.current_package.groupAssignmentPrice;
      }
      return this.priceData.current_package.studentAssignmentPrice;
    },
    credit() {
      if (isUserLoggedIn()) {
        return this.account.user.credit_amount;
      }
      return 0;
    },
  },
  methods: {
    saveAssignment() {
      const payload = {
        name: this.steps[0]?.data?.title,
        description: this.steps[0]?.data?.description || "",
        student_message: this.steps[0]?.data?.studentMessage || "",
        date_from: `${this.steps[0]?.data?.startDate}T${this.steps[0]?.data?.startTime}`,
        date_to: `${this.steps[0]?.data?.endDate}T${this.steps[0]?.data?.endTime}`,
        created_for: {
          entity: this.entity,
          entity_id: this.entityId,
        },
        config: {
          games: this.steps[2]?.data || {}
        }
      }

      assignmentService.addAssignment(this.entityId, payload) // TODO:Mentorship - handle errors
      .then((data) => {
        this.$store.commit("account/updateCredit", data.credit_amount);
        if(this.entity === "studentGroups"){
          this.$router.push(getStudentGroupAssignmentRoute(this.entityId, data.id));
        }
        if(this.entity === "myStudents"){
          this.$router.push(getMyStudentAssignmentRoute(this.entityId, data.id));
        }
      })
    },
    navigateToEntityPage() {
      return navigateToEntityPage(this);
    },
    navigateToStudentGroupPage() {
      this.$router.push(getStudentGroupRoute(this.entityId));
    },
    navigateToMyStudentPage() {
      this.$router.push(getMyStudentRoute(this.entityId));
    },
    getWizardStepColor(stepIdx) {
      if (this.currentStepIdx === stepIdx) {
        return "primary";
      }
      return "secondary";
    },
    loadGamesInfo() {
      this.gamesInfo = {};
      this.gamesInfoLoading = false;

      gamesService.get_info("all")
        .then((data) => {
          this.gamesInfo = data;
        })
        .catch((error) => handleError(error, router))
        .finally(() => {
          this.gamesInfoLoading = false;
        });
    },
    goToNextStep() {
      if(this.currentStepIdx === this.steps.length - 1){
        this.saveAssignment();
        return;
      }
      this.currentStepIdx = this.currentStepIdx + 1;
    },
    goToPreviousStep() {
      if(this.currentStepIdx === 0) {
        return;
      }
      this.currentStepIdx = this.currentStepIdx - 1;
    },
    handleDataChange(value) {
      this.steps = this.steps.map(
        (step, stepIdx) => stepIdx === this.currentStepIdx ? {...step, ...value} : {...step}
      )
    },
    loadStudentGroup() {
      this.entityLoading = true;

      mentorshipService.getStudentGroup(this.entityId).then((data) => {
        this.entityData = data.data;
        this.entityLoading = false;
      }).catch((error) => {
        this.error = error;
      })
    },
    loadMyStudent() {
      this.entityLoading = true;

      mentorshipService.getMyStudent(this.entityId).then((data) => {
        this.entityData = data.data;
        this.entityLoading = false;
      }).catch((error) => {
        this.error = error;
      })
    },
    loadAssignmentPrice() {
      this.priceLoading = true;

      assignmentService.getAssignmentPrice().then((data) => {
        this.priceData = data.data;
        this.priceLoading = false;
      }).catch((error) => {
        this.error = error;
      })
    }
  },
  created() {
    this.loadGamesInfo();
    if(this.entity === "studentGroups"){
      this.loadStudentGroup();
    }
    if(this.entity === "myStudents"){
      this.loadMyStudent();
    }
    this.loadAssignmentPrice();
  }
}
</script>